import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import IntegrationsCard from 'components/IntegrationsCard'
import SectionGetStarted from 'components/SectionGetStarted'

const IntegrationsPage = ({
  location,
  data: { Databases, SocialNetworks, WebApps, WebResources },
}) => (
  <Layout
    title="Supported Databases, APIs and Integrations"
    description="Use UseData to connect to any data source (Redshift, BigQuery, MySQL, PostgreSQL, MongoDB and many others), query, visualize and share your data to make your company data driven."
    location={location}
  >
    <section className="section section--pt-lg">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center m-b-100">
            <h1 className="serif">Integrations</h1>
            <p>
              Query all the data sources, both SQL, NoSQL and Web/App APIs, to answer complex questions
            </p>
          </div>
        </div>
        <div className="row">
          <h3 className="m-t-0 feature_font service-container">
            <span className="m-r-15 feature_icon"><i className="fa fa-bolt" aria-hidden="true"></i></span> Data Stores
          </h3>
          <div className="col-md-12 text-center">
            <div className="cards">
              {Databases.edges.map(({ node: { frontmatter } }) => {
                return (
                  <IntegrationsCard
                    name={frontmatter.name}
                    logo={frontmatter.logo}
                    path={frontmatter.path}
                    key={frontmatter.name}
                  />
                )
              })}
            </div>
          </div>
        </div>        
      </div>
    </section>

    <section className="section bg-soft-yellow">
      <div className="container">
        <div className="row">
          <h3 className="m-t-0 feature_font service-container">
            <span className="m-r-15 feature_icon"><i className="fa fa-cogs" aria-hidden="true"></i></span> Web Apps
          </h3>          
          <div className="col-md-12 text-center">
            <div className="cards">
              {WebApps.edges.map(({ node: { frontmatter } }) => {
                return (
                  <IntegrationsCard
                    name={frontmatter.name}
                    logo={frontmatter.logo}
                    path={frontmatter.path}
                    key={frontmatter.name}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="row">
          <h3 className="m-t-0 feature_font service-container">
            <span className="m-r-15 feature_icon"><i className="fa fa-cogs" aria-hidden="true"></i></span> Social Networks
          </h3>          
          <div className="col-md-12 text-center">
            <div className="cards">
              {SocialNetworks.edges.map(({ node: { frontmatter } }) => {
                return (
                  <IntegrationsCard
                    name={frontmatter.name}
                    logo={frontmatter.logo}
                    path={frontmatter.path}
                    key={frontmatter.name}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section bg-soft-yellow">
      <div className="container">
        <div className="row">
          <h3 className="m-t-0 feature_font service-container">
            <span className="m-r-15 feature_icon"><i className="fa fa-cogs" aria-hidden="true"></i></span> Web Resources
          </h3>          
          <div className="col-md-12 text-center">
            <div className="cards">
              {WebResources.edges.map(({ node: { frontmatter } }) => {
                return (
                  <IntegrationsCard
                    name={frontmatter.name}
                    logo={frontmatter.logo}
                    path={frontmatter.path}
                    key={frontmatter.name}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>        
    <SectionGetStarted />
  </Layout>
)

export const cardsQuery = graphql`
  {
    Databases: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___name] }
      filter: { fileAbsolutePath: { regex: "/_databases/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            logo
            path
          }
        }
      }
    }

    SocialNetworks: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___name] }
      filter: { fileAbsolutePath: { regex: "/_social_networks/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            logo
            path
          }
        }
      }
    }

    WebApps: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___name] }
      filter: { fileAbsolutePath: { regex: "/_web_apps/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            logo
            path
          }
        }
      }
    }
    
    WebResources: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___name] }
      filter: { fileAbsolutePath: { regex: "/_web_resources/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            logo
            path
          }
        }
      }
    }
  }
`

export default IntegrationsPage
